<template>
  <v-tooltip v-show="file" top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-bind="attrs" v-on="on" :small="small" outlined @click="handleClick">
        <v-icon :small="small" left>mdi-paperclip</v-icon>{{ file.original_name }}
      </v-chip>
    </template>
    <v-img v-if="file.preview_image_url" :src="file.preview_image_url" :alt="file.original_name" max-height="200" max-width="200" position="top center" class="my-2 mb-1">
      <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
        </div>
      </template>
    </v-img>
    ダウンロード({{ file.size | prettyBytes(1) }})
  </v-tooltip>
</template>

<script>
export default {
  name: 'FileAttachmentChip',
  props: {
    file: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      hasError: false
    }
  },
  methods: {
    async isFileUrlValid () {
      try {
        const response = await fetch(this.file.file_url, {
          method: 'GET',
          cache: 'no-cache'
        })
        return response.ok
      } catch (error) {
        return false
      }
    },
    async handleClick (event) {
      event.preventDefault()

      if (!this.hasError) {
        const isUrlValid = await this.isFileUrlValid()
        if (isUrlValid) {
          window.open(this.file.file_url, '_blank', 'noopener')
          return
        }
      }

      this.hasError = true
      this.$store.commit('setFlashError', 'ダウンロードURLの有効期限が切れました。<br>リロードして、改めて対象ファイルにアクセスください。')
    }
  }
}
</script>
